import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import ButtonGreen from '../../../components/Buttons/ButtonGreen.js'

const NavigationBookRouteAndSchedule = styled.div`
  // 🎛
  // 🎨
  // 🖋
  font-family: 'Open Sans';
  width: 100%;
`;

const PGComponentWrapper = styled.div`
  // 📰
  padding-bottom: 10px;
  padding-top: 10px;
  margin: 0 auto;
  max-width: 1360px;
  margin-bottom: 30px;
  display: grid;
  grid-template-rows: 40px;
  grid-template-columns: 1fr 2fr 2fr 1fr;
  grid-template-areas:
  "sg_btn_previous sg_btn_wrapper sg_btn_wrapper sg_btn_next";
  .ButtonWrapper {
    .ButtonPrevious {
      grid-template-areas: sg_btn_previous;
    }
    .ButtonGreen {
      grid-template-areas: sg_btn_next;
    }
    .ButtonGreen {
      grid-template-areas: sg_btn_wrapper;
    }
  }
`;

const ButtonWrapper = styled.div`
`;


const EchoShipNavStepper = () => {
  return (
    <NavigationBookRouteAndSchedule>
        <PGComponentWrapper>
        <ButtonWrapper></ButtonWrapper>
        <ButtonWrapper></ButtonWrapper>
        <ButtonWrapper></ButtonWrapper>
        
        <Link to="/BookShipment/AddItems/">
          <ButtonGreen 
            buttonText="Continue"
          />
        </Link>
        </PGComponentWrapper>

    </NavigationBookRouteAndSchedule>
  )
}

export default EchoShipNavStepper;