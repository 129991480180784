import React from 'react'
import styled from 'styled-components'

const ProgressIndicator = styled.div`

.es-c-container--stepper {
  // 🎛
  // 🎨
  background-color: #fff;
  border-bottom: 4px solid #e7edf0;
  // 
  font-family: 'Open Sans';
  color: #000;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.2px;
  .stepper-wrapper {
  margin: 0 auto;
  margin-bottom: -4px;
  max-width: 1360px;
  // 📰
  display: grid;
  grid-template-rows: 65px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  grid-template-areas:
    "a_stepOne a_stepTwo a_stepThree a_stepFour";

    .es-a-stepOne {
      grid-area: a_stepOne; // 🗺
      border-bottom: 4px solid #768287;
      width: 100%;
      align-self: end;
      // 🎨
      &.active{
        border-bottom: 4px solid #3daf2c;
        width: 100%;
        p {
            font-weight: 700;
            opacity: 1;
          }
        p span {
          font-weight: 700;
          opacity: 1;
        }
      }
      &.complete{
        border-bottom: 4px solid #26C1F9;
        width: 100%;
        p {
            font-weight: 500;
            opacity: 1;
          }
        p span {
          font-weight: 500;
          opacity: 1;
        }
      }
    }
    .es-a-stepTwo {
      grid-area: a_stepTwo; // 🗺
      border-bottom: 4px solid #768287;
      width: 100%;
      align-self: end;
      &.active {
        border-bottom: 4px solid #3daf2c;
        width: 100%;
        p {
            font-weight: 700;
            opacity: 1;
          }
        p span {
          font-weight: 700;
          opacity: 1;
        }
      }
      &.complete{
        border-bottom: 4px solid #26C1F9;
        width: 100%;
        p {
            font-weight: 500;
            opacity: 1;
          }
        p span {
          font-weight: 500;
          opacity: 1;
        }
      }
      // 🎛
      // 🎨
    }
    .es-a-stepThree {
      grid-area: a_stepThree; // 🗺
      border-bottom: 4px solid #768287;
      width: 100%;
      align-self: end;
      &.active{
        border-bottom: 4px solid #3daf2c;
        width: 100%;
        p {
            font-weight: 700;
            opacity: 1;
          }
        p span {
          font-weight: 700;
          opacity: 1;
        }
      }
      &.complete{
        border-bottom: 4px solid #26C1F9;
        width: 100%;
        p {
            font-weight: 500;
            opacity: 1;
          }
        p span {
          font-weight: 500;
          opacity: 1;
        }
      }
      // 🎛
      // 🎨
    }
    .es-a-stepFour {
      grid-area: a_stepFour; // 🗺
      border-bottom: 4px solid #768287;
      width: 100%;
      align-self: end;
      &.active{
        border-bottom: 4px solid #3daf2c;
        width: 100%;
        p {
            font-weight: 700;
            opacity: 1;
          }
        p span {
          font-weight: 700;
          opacity: 1;
        }
      }
      &.complete{
        border-bottom: 4px solid #26C1F9;
        width: 100%;
        p {
            font-weight: 500;
            opacity: 1;
          }
        p span {
          font-weight: 500;
          opacity: 1;
        }
      }
      // 🎛
      // 🎨
    }
    p {
        color: #2b363b;
        font-size: 15px;
        font-weight: 500;
        padding-bottom: 20px;
        opacity: 0.3;
      }
    p span {
      font-size: 30px;
      font-weight: 500;
      padding-right: 6px;
      color: #2b363b;
      opacity: 0.3;
    }
  }
}

// Define Responsive Layout Grid - Desktop Spec 🛠
@media only screen and (min-width: 768px) {
  .es-c-container--stepper {
    .stepper-wrapper {
    display: grid;
    grid-template-rows: 67px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
    "a_stepOne a_stepTwo a_stepThree a_stepFour";
    
    .es-a-stepOne {
      grid-area: a_stepOne; // 🗺
      // 🎨
    }
    .es-a-stepTwo {
      grid-area: a_stepTwo; // 🗺
      // 🎛
      // 🎨
    }
    .es-a-stepThree {
      grid-area: a_stepThree; // 🗺
      // 🎛
      // 🎨
    }
    .es-a-stepFour {
      grid-area: a_stepFour; // 🗺
      // 🎛
      // 🎨
    }
  }
  }
}
`

const PageComponentProgressIndicator = () => {
  return (
    <ProgressIndicator>
      <div className="es-c-container--stepper">
        <div className="stepper-wrapper">
          <div className="es-a-stepOne active">
            <p>
              <span>1</span>Route & Schedule
            </p>
          </div>
          <div className="es-a-stepTwo">
            <p>
              <span>2</span>Add Items
            </p>
          </div>
          <div className="es-a-stepThree">
            <p>
              <span>3</span>Select Carrier
            </p>
          </div>
          <div className="es-a-stepFour">
            <p>
              <span>4</span>Review & Dispatch{" "}
            </p>
          </div>
        </div>
      </div>
    </ProgressIndicator>
  )
}

export default PageComponentProgressIndicator;