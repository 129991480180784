import React from 'react'
import styled from 'styled-components'

import '../styles/spectre.css'

const SwitchAmPm = styled.div`

  display: grid;

  grid-template-areas:
    "g_min_cargo g_new_used";
    .form-group.es-i-min-cargo-liability {
      grid-area: g_min_cargo;
    }
    .es-i-new-used-switch {
      grid-area: g_new_used;
      align-self: center;
      font-family: "Open Sans";
      font-weight: 600;
      color: #9A9D9F;
      font-size: 14px;
      padding-left: 10px;
    .btn {
      border: 1px solid #BBBBBB;
      display: inline-block;
      padding: 4px;
      position: relative;
      text-align: center;
      vertical-align: middle;
      transition: background 600ms ease, color 600ms ease;
    }

input[type="radio"].toggle {
  display: none;
  & + label{
      cursor: pointer;
      font-weight: 700;
      font-size: 14px;
      color: #999d9f;
      &:after{
        background: #E8EDF0;
        content: "";
        height: 100%;
        position: absolute;
        top: 0;
        transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
        width: 100%;
        z-index: -1;
      }
  }
  &.toggle-left + label {
      height: 45px;
      width: 55px;

      margin-top: 19px;
      border-right: 0;
      padding-top: 15px;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      &:after{
          left: 100%
      }
  }
  &.toggle-right + label{
      height: 45px;
      width: 55px;

      margin-top: 19px;
      margin-left: -5px;
      padding-top: 15px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
      &:after{
          left: -100%;
      }
  }
  &:checked + label {
      cursor: default;
      color: #666666;
      background-color: #e7edf0;
      font-weight: 700;
      font-size: 14px;
      transition: color 300ms;
      &:after{
          left: 0;
      }
  }
}
}
`;

const EchoShipInput = (props) => {
  return (
    <SwitchAmPm>
      <div className="es-i-new-used-switch">
        <input id="toggle-on" className="toggle toggle-left" name="toggle" defaultValue="false" type="radio" defaultChecked />
        <label htmlFor="toggle-on" className="btn">AM</label>
        <input id="toggle-off" className="toggle toggle-right" name="toggle" defaultValue="true" type="radio" />
        <label htmlFor="toggle-off" className="btn">PM</label>
      </div>
    </SwitchAmPm>
  )
}

export default EchoShipInput;