import React from 'react'
import styled from 'styled-components'

import DayPickerInput from "react-day-picker/DayPickerInput";

import '../styles/spectre.css';
import '../styles/icons/css/icons.css';
import '../styles/calendar-input.css';

const InputSearchShipment = styled.div`
.form-group.es-i-address-1 {

  font-family: "Open Sans";
  label.form-label {
    margin-bottom: 5px;
    font-weight: 700;
    color: #666;
    font-size: 14px;
    font-weight: 600;
    span {
      color: #26c1fb;
    }
  }
}
.input-wrapper {
    position: relative;
    width: 200px;
}
 
.input-wrapper:before {
    content: "\f073";
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    text-decoration: inherit;
    color: #459ce7;
    font-size: 18px;
    padding-right: 0.5em;
    position: absolute;
    top: 10px;
    right: 0;
}

input {
  width: 100%;
  padding-right: 30px;
}

`;

const EchoShipInput = () => {
  return (
    <InputSearchShipment>
        <label className="form-label label-lg" htmlFor="input-requested-delivery-date">
          Requested Delivery Date <span>*</span>
        </label>
 
          <DayPickerInput placeholder="DD/MM/YYYY" format="DD/MM/YYYY" />
    </InputSearchShipment>
  )
}

export default EchoShipInput;