import React from 'react'
import styled from 'styled-components'


// Import Page Components
import FunctionalComponentBookDestination from '../../../FunctionalComponents/FunctionalComponentBookDestination.js'
import InputCalendar from '../../../InputComponents/InputCalendar.js'
import NavigationBookRouteAndSchedule from '../../../PageComponents/Navigation/BookShipment/NavigationRouteAndSchedule.js'
import FunctionalComponentNewShipmentOrigin from '../../../FunctionalComponents/FunctionalComponentNewShipmentOrigin.js'

const GContentBookRouteAndSchedule = styled.div`
  background: transparent;
  // 🖋
  font-family: 'Open Sans';
  // 📰
  display: grid;
  margin-top: 20px;
  grid-template-rows: 425px 250px 175px 150px;
  grid-template-columns: 1fr;
  grid-template-areas:
    "sg_blind_origin"
    "sg_blind_destination"
    "sg_calendar_input"
    "sg_navigation_stepper";
    
  .SGBlindOrigin {
    grid-area: sg_blind_origin; // 🗺
    background: none !important;
  }
  .SGBlindDestination {
    grid-area: sg_blind_destination; // 🗺
    background: none !important;
  }
  .SGCalendarInput {
    grid-area: sg_calendar_input; // 🗺
    background: none !important;
    justify-self: start;
  }
  .SGNavigationStepper {
    grid-area: sg_navigation_stepper; // 🗺
    background: none !important;
    align-self: start;
  }
  .DayPickerInput {
    width: 20%;
}
`;

const SGBlindOrigin = styled.div`

`;
const SGBlindDestination = styled.div`

`;
const SGCalendarInput = styled.div`

`;
const SGNavigationStepper = styled.div`

`;

const SGCalendarTest = styled.div`
  display: grid;
  margin: 0 auto;
  max-width: 1360px;
  justify-self: start;
  grid-template-rows: 0.5fr;
  grid-template-columns: 1fr;
`;



const EchoShipGridComponentContent = () => {
  return (

    <GContentBookRouteAndSchedule>
      <SGBlindOrigin>
      <FunctionalComponentNewShipmentOrigin></FunctionalComponentNewShipmentOrigin>

      </SGBlindOrigin>

      <SGBlindDestination>
          <FunctionalComponentBookDestination></FunctionalComponentBookDestination>
      </SGBlindDestination>

      <SGCalendarInput>
          <SGCalendarTest>
              <InputCalendar></InputCalendar>
          </SGCalendarTest>
      </SGCalendarInput>

      <SGNavigationStepper>
        <NavigationBookRouteAndSchedule></NavigationBookRouteAndSchedule>
      </SGNavigationStepper>

    </GContentBookRouteAndSchedule>

  )
}

export default EchoShipGridComponentContent;