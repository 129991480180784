import React from 'react'
import styled from 'styled-components'

// 💅
const ButtonGreen = styled.button `
    font-family: 'Open Sans';
    background-color: #3daf2c;
    border: 0.1rem solid #3daf2c;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    height: 42px !important;
    letter-spacing: 0rem;
    padding: 6px 56px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    white-space: nowrap;
    -webkit-font-smoothing: antialiased;
    transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
      &:focus,
      &:hover {
        color: #fff;
        outline: 0;
        background-color: #3daf2c;
        border-color: #3daf2c;
        opacity: 0.9;
    }
`;

// 🕳
const Button = (props) => {
  return (
      <ButtonGreen>{props.buttonText}</ButtonGreen>
  )
}
export default Button