import React from "react";
import { Modal } from "semantic-ui-react";
import '../../styles/modal.css';

import styled from "styled-components";
import ModalButton from '../../components/Buttons/ModalButtons/ModalButton.js'

// 💅
const ModalCreateNewLocation = styled.div`
/* border-radius: 4px;
  -webkit-box-shadow: 0 5px 15px rgba(0,0,0,.5);
  box-shadow: 0 5px 15px rgba(0,0,0,.5); */
  max-width: 960px;
  .form-select.select-lg.select-freight {
    width: 100%;
  }
  .row-2 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
  .form-input.input-lg.input-address {
    width: 40%;
  }
  .row-3 {
    display: grid;
    grid-template-columns: 0.5fr 0.25fr 0.25fr ;
    grid-template-rows: 1fr;
    .form-input.input-lg.input-city {
      width: 90%;
    }
    .form-select.select-lg.select-state {
      width: 90%;
    }
    .form-input.input-lg.input-zip {
      width: 90%;
    }  
  }
  .row-4 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    .form-select.select-lg.select-country {
      width: 100%;
    }
  }


  .form-label.label-lg {
    margin-bottom: none;
  }
  .form-label.label-state {
    margin-bottom: .4rem;
  }
`;

const ModalGrid = styled.div`
  max-width: 960px;
  // 📰
  display: grid;
  grid-template-rows: 0.2fr 1fr 0.2fr;
  grid-template-columns: 0.3fr 0.7fr;
  grid-template-areas:
  "g_modal_header g_modal_header"
  "g_modal_content_left g_modal_content_right"
  "g_modal_footer g_modal_footer";
`;

// MODAL GRID: HEADER
const ModalHeader = styled.div`
  grid-area: g_modal_header; // 🗺
  min-height: 85px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: solid 2px #e7edf0;

  .ModalTitle {
    font-family: Teko, "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    line-height: 1.5;
    font-size: 30px;
    color: #141b1f;
    text-align: center;
  }
  .ui.page.modals.dimmer.transition.visible.active {
    background: #000 !important;
  }
  .ui.modal.transition.visible.active {
    background: #000 !important;
  }
  .ui.modal {
    margin: 0 auto !important;
  }
`;

// MODAL GRID: CONTENT LEFT
const ModalGridContentLeft = styled.div`
  grid-area: g_modal_content_left; // 🗺
  padding-top: 20px;
  padding-right: 15px;
  padding-left: 15px;

  .Title {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    color: #141b1f;
    font-size: 12px;
    font-weight: 400;
    color: #2b363b;
  }
  .SubText {
    margin: 0;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #141b1f;
    padding-top: 12px;
    line-height: 1.42857143;
  }
`;

// MODAL GRID: CONTENT RIGHT
const ModalGridContentRight = styled.div`
  grid-area: g_modal_content_right; // 🗺
  border-left: solid 2px #e7edf0;
  padding: 15px;
`;

// MODAL GRID: FOOTER
const ModalFooter = styled.div`
  grid-area: g_modal_footer; // 🗺
  border-top: solid 2px #e7edf0;  
`;

const FooterWrap = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;

`;

const ButtonConfirmLocation = styled.button`
  font-family: 'Open Sans';
  justify-self: center;
  align-self: center;
  margin-top: 20px;
  background-color: #26c1fb;
  border: 0.1rem solid #26c1fb;
  border-radius: 3px;
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px !important;
  display: inline-block;
  height: 45px;
  letter-spacing: 0rem;
  padding: 0 1.5rem;
  text-align: center;
  text-transform: none;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
  transition: 0.6s cubic-bezier(0.2, 0.8, 0.2, 1);
  &:focus,
  &:hover {
    opacity: 0.9;
    color: #fff;
    outline: 0;
    background-color: #04abea;
    border: 0.1rem solid #04abea;
  }
`;


const ModalModalLocation = (props) => (
  <Modal trigger={<ModalButton newLocation>Create A New Location</ModalButton>}>

      <ModalCreateNewLocation>
      
        <ModalGrid>
          <ModalHeader>
            <div className="ModalTitle">Create A New Location</div>
            <div className="Exit" />
          </ModalHeader>

          <ModalGridContentLeft>
            <div className="Title">EXPERT TIP</div>
            <div className="SubText">
              Be as accurate as you can when adding a new destination address to avoid missed drop-offs and delays.
            </div>
          </ModalGridContentLeft>

          <ModalGridContentRight>
       {/* Description */}
          <div className="form-group es-i-min-cargo-liability">
            <label className="form-label label-lg">
              Address <span>*</span>
            </label>
            <input className="form-input input-lg" type="text" placeholder="" />
          </div>
          <div className="row-2">
            <div className="form-group es-i-min-cargo-liability">
              <label className="form-label label-lg">
                Address 2 (Suit/Apt)
              </label>
              <input className="form-input input-lg input-address" type="text" placeholder="" />
            </div>
          </div>
          {/* Row Two End*/}
          {/* Weight */}
          <div className="row-3">
            <div className="form-group es-i-min-cargo-liability">
              <label className="form-label label-lg input-city">
                City <span>*</span>
              </label>
              <input
                className="form-input input-lg input-city"
                type="text"
                placeholder=""
              />
            </div>
            {/* Unit */}
            <div className="form-group es-i-freight-class">
              <label className="form-label label-state" htmlFor="input-type-2">
                State <span>*</span>
              </label>
              <select className="form-select select-lg select-state">
                <option>LBS</option>
                <option>KG</option>
              </select>
            </div>
            {/* NMFC */}
            <div className="form-group es-i-min-cargo-liability">
              <label className="form-label label-lg">
                NMFC<span>*</span>
              </label>
              <input
                className="form-input input-lg input-zip"
                type="text"
                placeholder=""
              />
            </div>
          </div>

          <div className="row-4">
            {/* Handling Unit Type */}
            <div className="form-group es-i-freight-class">
              <label className="form-label" htmlFor="input-type-2">
                Country <span>*</span>
              </label>
              <select className="form-select select-lg select-country">
                <option>United States</option>
                <option>Canada</option>
              </select>
            </div>
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          </ModalGridContentRight>

          <ModalFooter > 
          <FooterWrap>
            <ButtonConfirmLocation>CONFIRM LOCATION DETAILS</ButtonConfirmLocation>
          </FooterWrap>
          </ModalFooter>
        </ModalGrid>
      </ModalCreateNewLocation>

  </Modal>
);
export default ModalModalLocation