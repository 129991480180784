import _ from "lodash";

import React, { Component } from "react";
import { Search } from "semantic-ui-react";
import "../../../styles/type-ahead.css";
import { Link } from 'gatsby'

// const source = _.times(15, () => ({
//   title: faker.company.companyName(),
//   description: faker.fake(
//     "{{address.streetAddress}}, {{address.city}}, {{address.stateAbbr}}, {{address.zipCode}}, {{address.country}}"
//   )
// }));

const source = _.times(1, () => ({
  "title": "Echo - Long Beach",
  "description": "100 W Broadway, Long Beach, CA 90802"
}));



export default class TypeAheadBookShipmentDestination extends Component {
  constructor(props){
    super(props);
    this.state = {}
  }

  componentWillMount() {
    this.resetComponent();
  }

  resetComponent = () =>
    this.setState({ isLoading: false, results: [], value: "" });

  handleResultSelect = (e, { result }) =>
    this.setState({ value: result.title });

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value });

    setTimeout(() => {
      if (this.state.value.length < 1) return this.resetComponent();

      const re = new RegExp(_.escapeRegExp(this.state.value), "i");
      const isMatch = result => re.test(result.title);

      this.setState({
        isLoading: false,
        results: _.filter(source, isMatch)
      });
    }, 300);
  };

  // onSearchClick(){
  //   var search_result = document.getElementById('destinationSearchResult');
  //   var show = search_result.classList.contains('hide');
  //   search_result.classList.toggle('hide', !show);
  // }

  render() {
    const { isLoading, value, results } = this.state;

    return (
        <Link to="/BookShipment/RouteAndScheduleDestination/">
          <Search
            fluid
            loading={isLoading}
            onResultSelect={this.handleResultSelect}
            onSearchChange={_.debounce(this.handleSearchChange, 500, {
              leading: true
            })}
            // onClick={this.onSearchClick}
            results={results}
            value={value}
            {...this.props}
          />
          </Link>
    );
  }
}